import agreePersonalDataModule from "Store/modules/agreePersonalData";
import alertsModule from "Store/modules/alerts";
import { registerAlertService } from "Store/modules/alerts/services/alertService";
import forgotPasswordModule from "Store/modules/forgotPassword";
import resetPasswordModule from "Store/modules/resetPassword";
import selectProfileModule from "Store/modules/selectProfile";
import signInModule from "Store/modules/signIn";
import signUpModule from "Store/modules/signUp";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
	state: {},
	mutations: {},
	actions: {},
	modules: {
		[alertsModule.namespace]: {
			...alertsModule
		},
		[signUpModule.namespace]: {
			...signUpModule
		},
		[signInModule.namespace]: {
			...signInModule
		},
		[forgotPasswordModule.namespace]: {
			...forgotPasswordModule
		},
		[resetPasswordModule.namespace]: {
			...resetPasswordModule
		},
		[selectProfileModule.namespace]: {
			...selectProfileModule
		},
		[agreePersonalDataModule.namespace]: {
			...agreePersonalDataModule
		}
	}
});

registerAlertService(store);

export default store;
