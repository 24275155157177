import { baseActionTypes } from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";

export const namespace = "select-profile";

export const getterTypes = {};

export const actionTypes = {
	...baseActionTypes,
	submit: "submit"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	SET_PROFILES: "SET_PROFILES",
	SET_IS_LOADING: "SET_IS_LOADING",
	SET_IS_SAVING: "SET_IS_SAVING"
};

const selectProfileTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default selectProfileTypes;
