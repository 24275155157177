import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/modules/selectProfile/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import { authorize, signInWithProfile } from "@/api/auth";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import SelectProfileState from "@/store/modules/selectProfile/types/selectProfileState";
import { resolveNestedState } from "@/utils/vuexModules";
import storeManager from "@/store/manager";
import router from "@/router";
import alertService from "@/store/modules/alerts/services/alertService";

const { VUE_APP_BASE_URL } = process.env;
const baseMixin = (new BaseMixinBuilder()).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new SelectProfileState();
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<SelectProfileState, any>>{};

const actions = <ActionTree<SelectProfileState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	async [actionTypes.initialize]({ commit, rootState }) {
		// @ts-ignore
		const profiles = resolveNestedState(rootState, storeManager.signIn.namespace).profiles;
		commit(mutationTypes.SET_PROFILES, profiles);
	},
	async [actionTypes.submit]({ commit, state }, { id, payload }) {
		commit(mutationTypes.SET_IS_SAVING, true);

		try {
			let profile = state.profiles.find(x => x.id === id);
			if(!profile) throw new Error("profile");

			let redirectUri = await signInWithProfile({
				profileId: profile.id,
				signedPayload: payload
			});

			window.location = await authorize(redirectUri);
		} catch (error: any) {
			console.error(error);

			if(error.detail) {
				alertService.addCustomError(error.detail);
			} else {
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_SAVING, false);
		}
	}
};

const mutations = <MutationTree<SelectProfileState>>{
	...stateManipulationMixin.mutations,
	[mutationTypes.SET_IS_LOADING](state, value) {
		state.isLoading = value;
	},
	[mutationTypes.SET_IS_SAVING](state, value) {
		state.isSaving = value;
	},
	[mutationTypes.SET_PROFILES](state, value) {
		state.profiles = value;
	}
};

export {
	namespace, state, getters, actions, mutations
};

const selectProfileModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default selectProfileModule;
