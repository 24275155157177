<template>
	<v-container fluid class="ma-0 pa-0">
		<frp-alerts-snackbar :text="errorAlert.text"
							 @close="onCloseErrorAlert(index)"
							 color="error"
							 right
							 :timeout="errorAlert.timeout"
							 v-for="(errorAlert, index) in errorAlerts"
							 :key="index">
		</frp-alerts-snackbar>
		<frp-alerts-snackbar :text="infoAlert.text"
							 @close="onCloseInfoAlert(index)"
							 right
							 color="info"
							 :timeout="infoAlert.timeout"
							 v-for="(infoAlert, index) in infoAlerts"
							 :key="index">
		</frp-alerts-snackbar>
	</v-container>
</template>

<script>
import FrpAlertsSnackbar from "Components/alerts/FrpAlertsSnackbar";
import { createNamespacedHelpers } from "vuex";
import { namespace } from "Store/modules/alerts";
import alertTypes from "Store/modules/alerts/types";

const { mapState, mapMutations } = createNamespacedHelpers(namespace);

export default {
	computed: {
		...mapState({
			errorAlerts: state => state.errorAlerts,
			infoAlerts: state => state.infoAlerts
		})
	},
	methods: {
		...mapMutations({
			onCloseErrorAlert: alertTypes.mutationTypes.REMOVE_ERROR_ALERT,
			onCloseInfoAlert: alertTypes.mutationTypes.REMOVE_INFO_ALERT
		})
	},
	components: {
		FrpAlertsSnackbar
	}
};
</script>
