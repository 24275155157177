<template>
	<v-col :cols="cols" :sm="sm" :md="md" :lg="lg" :xl="xl">
		<v-card class="py-6 px-6"
				rounded="lg"
				style="filter: drop-shadow(0px 6px 16px rgba(0, 123, 255, 0.08))"
				:class="{ 'px-1': $vuetify.breakpoint.smAndDown }"
				elevation="0">
			<v-card-title class="mb-4 align-center flex-column">
				<router-link :to="{ name: routeNames.base }">
					<frp-icon :color="colors.secondary.base">
						<logo-icon></logo-icon>
					</frp-icon>
				</router-link>
				<span class="text-body-2 primary--text text--darken-1 mt-5 text-break">{{ title }}</span>
			</v-card-title>
			<v-card-text>
				<slot></slot>
			</v-card-text>
		</v-card>
		<slot name="actions"></slot>
	</v-col>
</template>

<script>
import FrpIcon from "Components/icon/FrpIcon";
import colorsMixin from "Mixins/colorsMixin";
import LogoIcon from "Assets/img/icons/ico_logo.svg";
import routeNames from "Router/routeNames";

export default {
	mixins: [colorsMixin],
	components: { FrpIcon, LogoIcon },
	props: {
		title: String,
		toggleText: String,
		xl: {
			type: String,
			default: "6"
		},
		lg: {
			type: String,
			default: "8"
		},
		md: {
			type: String,
			default: "9"
		},
		sm: {
			type: String,
			default: "11"
		},
		cols: {
			type: String,
			default: "11"
		}
	},
	data() {
		return {
			routeNames
		}
	}
};
</script>
