export default class User {
	login: string;
	password: string;
	confirmedPassword: string;

	constructor(login: string, password: string, confirmedPassword: string) {
		this.login = login;
		this.password = password;
		this.confirmedPassword = confirmedPassword;
	}
}
