<template>
	<frp-sign-in-form-base :title="$t('titles.agreePersonalData')" sm="12" md="11" lg="10" xl="8">
		<div class="text-body-1 primary--text text--darken-1 mx-auto mt-1">
			{{ $t("content.personalData.text") }}
			<frp-link :href="documentLink">{{ $t("content.personalData.link") }}</frp-link>
		</div>

		<v-row class="flex-column">
			<v-col class="pt-0">
				<v-btn type="submit" class="frp-btn mt-6" color="secondary" block
					   @click="submit"
					   :loading="isSaving">
					{{ $t("buttons.next") }}
				</v-btn>
			</v-col>
		</v-row>
	</frp-sign-in-form-base>
</template>

<script>
import FrpLink from "@/components/common/FrpLink.vue";
import FrpSignInFormBase from "Views/shared/FrpSignInFormBase";
import FrpIcon from "Components/icon/FrpIcon";
import colorsMixin from "@/mixins/colorsMixin";
import routeNames from "Router/routeNames";
import storeManager from "@/store/manager";
import { actionTypes, mutationTypes } from "Store/modules/agreePersonalData/types";
import { createNamespacedHelpers } from "vuex";

const namespace = storeManager.agreePersonalData.namespace;
const { mapState, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin],
	data() {
		return {
			namespace,
			routeNames,
			documentLink: process.env.VUE_APP_AGREE_PERSONAL_DATA_DOCUMENT_LINK
		};
	},
	computed: {
		...mapState({
			isSaving: state => state.isSaving
		})
	},
	methods: {
		...mapActions({
			initialize: actionTypes.initialize,
			submit: actionTypes.submit
		})
	},
	async created() {
		await this.initialize();
	},
	components: {
		FrpLink,
		FrpSignInFormBase,
		FrpIcon
	}
};
</script>

<style lang="scss">
</style>
