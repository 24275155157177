const baseUrl = process.env.VUE_APP_BASE_API_URL;
const baseIdentityUrl = process.env.VUE_APP_BASE_IDENTITY_API_URL;

export const urlTemplateParts = {
	id: "{id}",
	subId: "{subId}"
};

const urls = {
	auth: {
		register: `${baseIdentityUrl}/auth/register`,
		login: `${baseIdentityUrl}/auth/login`,
		signInWithProfile: `${baseIdentityUrl}/connect/sign-in-with-profile`,
		logout: `${baseIdentityUrl}/auth/logout`,
		forgotPassword: `${baseIdentityUrl}/auth/forgot-password`,
		resetPassword: `${baseIdentityUrl}/auth/reset-password`,
		selectProfile: `${baseIdentityUrl}/connect/authorize`,
		agreePersonalData: `${baseIdentityUrl}/auth/agree-personal-data`
	},
	cryptopro: {
		getSignerCertificates: `${baseUrl}/jcp/signature/signer`
	}
};


export default urls;
