import ApiProfile from "@/api/types/auth/apiProfile";
import { Type } from "class-transformer";

export default class SelectProfileState {
	@Type(() => ApiProfile)
	profiles: ApiProfile[];
	isLoading: boolean;
	isSaving: boolean;

	constructor(profiles: ApiProfile[] = [], isLoading: boolean = false, isSaving = false) {
		this.profiles = profiles;
		this.isLoading = isLoading;
		this.isSaving = isSaving;
	}
}
