import client from "@/api/client/client";
import { prepareUrl } from "@/utils/prepareUrlQuery";
import urls, { urlTemplateParts } from "@/api/config/urls";
import ApiCreateUser from "@/api/types/auth/apiCreateUser";
import ApiLoginUser from "@/api/types/auth/apiLoginUser";
import ApiForgotPasswordParameters from "@/api/types/auth/apiForgotPasswordParameters";
import ApiAuthorizeResponse from "@/api/types/auth/apiAuthorizeResponse";
import { stringify } from "qs";

export const registerUser = async (payload: ApiCreateUser): Promise<void> => {
	try {
		await client.post<void>(prepareUrl(urls.auth.register), payload);
	} catch (e) {
		throw e;
	}
};

export const forgotPassword = async (payload: ApiForgotPasswordParameters): Promise<void> => {
	await client.post<void>(prepareUrl(urls.auth.forgotPassword), payload);
};

export const resetPassword = async (password: string): Promise<void> => {
	await client.post<void>(`${prepareUrl(urls.auth.resetPassword)}${window.location.search}`, { password });
};

export const login = async (payload: ApiLoginUser): Promise<ApiAuthorizeResponse> => {
	const query = window.location.search;
	return await client.post<ApiAuthorizeResponse>(`${prepareUrl(urls.auth.login)}${query}`,
		payload, {
			withCredentials: true
		});
};

export const authorize = async (url: string): Promise<any> => {
	let { redirectUri } = await client.get<{ redirectUri: string }>(`${url}`, {
		withCredentials: true
	});

	return redirectUri;
};

export const signInWithProfile = async (payload: any): Promise<any> => {
	const query = window.location.search;
	let { redirectUri } = await client.post<any>(`${prepareUrl(urls.auth.signInWithProfile)}${query}`,
		payload, {
			withCredentials: true
		});

	return redirectUri;
};

export const agreePersonalData = async (agreedPersonalData: boolean) => {
	return await client.post<void>(urls.auth.agreePersonalData,
		{ agreedPersonalData }, {
			withCredentials: true
		});
};
