import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/modules/signIn/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import FormMixinBuilder from "@/store/shared/form";
import SignInState from "@/store/modules/signIn/types/signInState";
import SignInUser from "@/store/modules/signIn/types/signInUser";
import { authorize, login, signInWithProfile } from "@/api/auth";
import ApiLoginUser from "@/api/types/auth/apiLoginUser";
import HttpNotFoundException from "@/exceptions/httpNotFoundException";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import routeNames from "@/router/routeNames";
import router from "@/router";

const formMixin = (new FormMixinBuilder()).build();
const baseMixin = (new BaseMixinBuilder()).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new SignInState(
			formMixin.state(),
			new SignInUser("", ""),
			[],
			""
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<SignInState, any>>{
	...formMixin.getters
};

const actions = <ActionTree<SignInState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	async [actionTypes.initialize]({ dispatch }) {
		dispatch(actionTypes.resetState);
	},
	async [actionTypes.save]({ commit, state }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		try {
			let { profiles, redirectUri, agreedPersonalData } = await login(new ApiLoginUser(state.user.login, state.user.password));

			commit(mutationTypes.SET_PROFILES, profiles);
			commit(mutationTypes.SET_REDIRECT_URI, redirectUri);

			if(!agreedPersonalData)
				return await router.push({ name: routeNames.agreePersonalData, query: router.currentRoute.query });

			let callbackUrl = await authorize(redirectUri);

			switch (profiles.length) {
				case 0:
				{
					window.location = callbackUrl;
					break;
				}
				case 1:
				{
					let [profile] = profiles;
					if(!profile.isSignatureRequired) {
						let redirectUri = await signInWithProfile({
							profileId: profile.id
						});

						window.location = await authorize(redirectUri);
					} else {
						await router.push({ name: routeNames.selectProfile, query: router.currentRoute.query });
					}

					break;
				}
				default:
				{
					await router.push({ name: routeNames.selectProfile, query: router.currentRoute.query });
				}
			}
		} catch (error: any) {
			switch (error.constructor) {
				case HttpNotFoundException:
					alertService.addError(AlertKeys.ACCOUNT_NOT_FOUND_ERROR);
					break;
				default:
					AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	}
};

const mutations = <MutationTree<SignInState>>{
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	[mutationTypes.SET_LOGIN](state, value) {
		state.user.login = value;
	},
	[mutationTypes.SET_PASSWORD](state, value) {
		state.user.password = value;
	},
	[mutationTypes.SET_PROFILES](state, value) {
		state.profiles = value;
	},
	[mutationTypes.SET_REDIRECT_URI](state, value) {
		state.redirectUri = value;
	}
};

export {
	namespace, state, getters, actions, mutations
};

const signInModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default signInModule;
