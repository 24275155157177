<template>
	<v-responsive height="180">
		<v-container class="fill-height">
			<v-row align="center">
				<v-col cols="12" class="d-flex justify-center align-center flex-column">
					<v-progress-circular
						:size="64"
						:width="3"
						color="secondary"
						indeterminate
					></v-progress-circular>
					<span class="mt-7 text-caption">Пожалуйста, подождите</span>
				</v-col>
			</v-row>
		</v-container>
	</v-responsive>
</template>

<script>
export default {};
</script>

<style scoped>

</style>
