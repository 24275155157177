import { baseActionTypes } from "@/store/shared/base/types";
import { formTypes } from "@/store/shared/form/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";

export const namespace = "sign-up";

export const getterTypes = {
	...formTypes.getterTypes
};

export const actionTypes = {
	...baseActionTypes,
	...formTypes.actionTypes
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...formTypes.mutationTypes,
	SET_LOGIN: "SET_LOGIN",
	SET_PASSWORD: "SET_PASSWORD",
	SET_CONFIRMED_PASSWORD: "SET_CONFIRMED_PASSWORD"
};

const signUpTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default signUpTypes;
