import { CounterpartyType } from "@/types/CounterpartyType";

export default {
	fields: {
		date: {
			placeholder: "Введите дату"
		},
		login: {
			label: "Email / ИНН организации",
			placeholder: "Введите ИНН или Email"
		},
		password: {
			label: "Пароль",
			placeholder: "Введите пароль"
		}
	},
	alerts: {
		errors: {
			loadExternalDataError: "Ошибка при получении данных",
			onSaveExternalError: "Возникла ошибка при сохранении данных",
			onCalibrationExternalError: "Возникла ошибка при запуске калибровки",
			settings: {
				errorUpdate: "Ошибка изменения данных пользователя"
			}
		},
		info: {
			onSuccessCreated: "Данные успешно сохранены",
			onSuccessUpdated: "Данные успешно обновлены",
			onSuccessStartCalibration: "Калибровка успешно запущена",
			settings: {
				successUpdated: "Данные пользователя успешно обновлены"
			}
		}
	},
	buttons: {
		loadMore: "Загрузить еще",
		cancel: "Отменить",
		choose: "Выбрать",
		skip: "Пропустить",
		save: "Сохранить",
		dontSave: "Не сохранять",
		ok: "Ок",
		backToList: "Назад к списку",
		add: "Добавить",
		remove: "Удалить",
		update: "Обновить",
		back: "Назад",
		next: "Далее",
		login: "Войти",
		logout: "Выйти",
		register: "Зарегистрироваться",
		personalArea: "Личный кабинет",
		signIn: "Вход",
		account: "Пользователь",
		goToHome: "На главную",
		profileUser: "Профиль пользователя",
		continue: "Продолжить",
		restoreAccess: "Восстановить доступ"
	},
	common: {
		noData: "Отсутствуют данные",
		numbers: {
			adjectives: {
				"1": "Первый",
				"2": "Второй",
				"3": "Третий",
				"4": "Четвертый",
				"5": "Пятый",
				"6": "Шестой",
				"7": "Седьмой"
			}
		},
		help: "Справка"
	},
	dialogs: {
		confirmCancelFormChanges: {
			title: "Отменить изменения?",
			description: "Данное действие невозможно будет отменить"
		},
		confirmUnsavedChanges: {
			title: "Сохранить изменения?",
			description: "Форма содержит несохраненные изменения"
		},
		acceptUnappliedChanges: {
			title: "Предупреждение",
			description: "Система не сохранила некоторые из параметров",
			accept: "Понятно"
		},
		datePicker: {
			submit: "Подтвердить",
			cancel: "Отмена"
		}
	},
	tooltips: {
		signIn: "Для входа в личный кабинет введите email зарегистрированного пользователя от организации. Для входа в мастер-аккаунт введите ИНН организации"
	},
	errorsPages: {
		pageNotFound: "Что-то пошло не так, давайте попробуем сначала!",
		internalServer: "500 (Ошибка сервера)"
	},
	placeholders: {
		email: "email@mail.ru",
		password: "Введите пароль"
	},
	hints: {
		range: "Диапазон значений от {min} до {max}"
	},
	logs: {
		error: {
			requestError: "Ошибка выполнения запроса"
		},
		info: {
			request: "Запрос {url}",
			response: "Ответ {url}"
		}
	},
	navigation: {
		applications: {
			index: "Запросы и обращения",
			masterAccountAccess: "Доступ к мастер-аккаунту"
		},
		admin: {
			index: "Администрирование",
			counterparties: "Контрагенты",
			offices: "Фонд"
		},
		finance: {
			title: "Финансовый блок"
		},
		procuring: {
			title: "Обеспечение"
		},
		support: {
			title: "Техподдержка"
		},
		common: {
			legalEntity: "Организации",
			users: "Пользователи"
		}
	},
	subheaders: {},
	titles: {
		applications: "Список заявок",
		welcome: "Добро пожаловать!",
		agreePersonalData: "Согласие на обработку персональных данных"
	},
	validation: {
		inn: "Неверный ИНН",
		validPassword: "Неверный пароль",
		required: "Поле не может быть пустым",
		maxNumber: "Не более {number}",
		minNumber: "Не менее {number}",
		maxDate: "Не позднее {number}",
		minDate: "Не раньше {number}",
		validOnlyNumbers: "Поле принимает только числа",
		validOnlyIntegerNumbers: "Поле принимает только целые числа",
		validIpAddress: "Неверный формат адреса",
		invalidDate: "Неверная дата",
		validEmail: "Неверный формат почты",
		validConfirmedEmail: "Адреса почты не совпадают"
	},
	tables: {
		loading: "Загрузка данных..."
	},
	aliases: {
		approveStatus: {
			Created: "Открыта",
			Approved: "Одобрена",
			Rejected: "Отклонена",
			Undefined: "Неизвестно"
		},
		counterpartyType: {
			[CounterpartyType.LEGAL_ENTITY]: "Юридическое лицо",
			[CounterpartyType.LEGAL_PERSON]: "Индивидуальный предприниматель",
			[CounterpartyType.PHYSICAL_PERSON]: "Физическое лицо",
			[CounterpartyType.FOREIGN_ORGANIZATION]: "Иностранная организация"
		}
	},
	content: {
		personalData: {
			text: "Нажимая кнопку «Далее», Вы подтверждаете согласие лица, данные о котором указаны в форме, на обработку персональных данных, в соответствии с Федеральным законом от 27.07.2006 года № 152-ФЗ «О персональных данных», на условиях и для целей, определенных в",
			link: "Согласии на обработку персональных данных"
		}
	}
};
