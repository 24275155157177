import AlertsStateModel from "@/store/modules/alerts/models/alertsStateModel";
import { mutationTypes, namespace } from "@/store/modules/alerts/types";
import AlertModel from "@/store/modules/alerts/models/alertModel";
import { MutationTree } from "vuex";

const state = new AlertsStateModel({
	errorAlerts: [],
	infoAlerts: []
});

const getters = {};

const actions = {
};

const mutations = <MutationTree<AlertsStateModel>>{
	[mutationTypes.ADD_ERROR_ALERT](state, value) {
		state.errorAlerts.push(new AlertModel({ text: value, timeout: 8000 }));
	},
	[mutationTypes.REMOVE_ERROR_ALERT](state, index) {
		state.errorAlerts.splice(index, 1);
	},
	[mutationTypes.ADD_INFO_ALERT](state, value) {
		state.infoAlerts.push(new AlertModel({ text: value, timeout: 3000 }));
	},
	[mutationTypes.REMOVE_INFO_ALERT](state, index) {
		state.infoAlerts.splice(index, 1);
	}
};

export {
	namespace, state, getters, actions, mutations
};

export default {
	namespace, state, getters, actions, mutations, namespaced: true
};
