<template>
	<div :style="styleObject" class="d-flex" @click="$emit('click')">
		<slot></slot>
	</div>
</template>

<script>
export default {
	props: {
		color: String
	},
	computed: {
		styleObject() {
			return {
				color: this.color
			};
		}
	},
	components: {}
};
</script>
