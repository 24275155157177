<template>
	<a class="text-decoration-underline frp-link-btn d-flex align-center"
	   :style="{color}"
	   :href="href"
	   :target="target"
	   v-on="$listeners">
		<template v-if="icon">
			<frp-icon :src="icon"
					  :color="color"
					  class="mr-2">
				<slot name="icon"></slot>
			</frp-icon>
		</template>
		<slot name="content"></slot>
	</a>
</template>

<script>
import FrpIcon from "@/components/icon/FrpIcon.vue";
export default {
	components: { FrpIcon },
	props: {
		icon: {
			type: Boolean,
			default: false
		},
		color: {
			type: String,
		},
		href: {
			type: String,
			default: undefined
		},
		target: {
			type: String,
			default: "_self"
		}
	}
};
</script>

<style scoped lang="less">
.frp-link-btn {
	font-size: 12px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0;
	text-align: right;
	color: var(--v-primary-base)
}
</style>
