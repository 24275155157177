import Vue from "vue";
import Vuetify from "vuetify";
// @ts-ignore
import ru from "vuetify/es5/locale/ru";
import "Plugins/vuetify/style.less";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		options: {
			customProperties: true
		},
		themes: {
			light: {
				primary: {
					base: "#002333",
					darken1: "#000000"
				},
				secondary: {
					base: "#DD052B",
					lighten1: "#FCE5EA"
				},
				warning: "#FE7A00",
				grey: {
					base: "#6C6D70",
					lighten1: "#F2F5F5"
				},
				blue: {
					base: "#0D74BB",
					darken3: "#0F1F34",
					darken4: "#0A1729"
				},
				green: {
					base: "#01865B"
				},
				accent: "#A989EE",
				error: "#FB104A",
				success: "#29CC97",
				white: {
					base: "#FFFFFF",
					darken1: "#F0F1F5",
					darken2: "#ECECEC",
					darken3: "#E6E7EA",
					darken4: "#DFE0EB",
					//В теме VuetifyParsedThemeItem(Vuetify) нет Darken5, к тому darken 5 не используется
					//darken5: "#C4C4C4"
				}
			}
		}
	},
	lang: {
		locales: { ru },
		current: "ru"
	}
});
