import { baseActionTypes } from "@/store/shared/base/types";
import { formTypes } from "@/store/shared/form/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";

export const namespace = "reset-password";

export const GetterTypes = {
	...formTypes.getterTypes
};

export const ActionTypes = {
	...baseActionTypes,
	...formTypes.actionTypes
};

export const MutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...formTypes.mutationTypes,
	SET_PASSWORD: "SET_PASSWORD",
	SET_MODE: "SET_MODE"
};

const resetPasswordTypes = {
	namespace, ActionTypes, MutationTypes, GetterTypes
};

export default resetPasswordTypes;
