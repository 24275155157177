import { baseActionTypes } from "Store/shared/base/types";
import stateManipulationMixinTypes from "Store/shared/stateManipulation/types";

export default class BaseMixinBuilder {
	constructor() {
	}

	build() {
		return {
			actions: {
				[baseActionTypes.initialize]({ dispatch }) {
				},
				[baseActionTypes.destroy]({ dispatch }) {
					console.debug("destroy");
					dispatch(stateManipulationMixinTypes.actionTypes.resetState);
				}
			}
		};
	}
}
