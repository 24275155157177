<template>
	<v-card class="frp-digital-signature-certificate rounded-lg" v-ripple="false"
			:disabled="disabled"
			outlined
			@click="select"
			elevation="0">
		<v-radio color="secondary" :value="value" class="pl-2">
			<template #label>
				<div class="d-flex flex-column ml-n2">
					<v-card-title class="text-subtitle-2 primary--text font-weight-medium pb-2">
						{{ certificate.title }}
					</v-card-title>
					<v-card-text class="pb-2">
						<frp-details-group dense>
							<v-simple-table dense class="frp-certificate-data-table">
								<tbody>
									<tr v-for="item in items" :key="item.title">
										<td class="px-0 text-body-2 primary--text" style="height: 29px">{{ item.title }}</td>
										<td class="px-0 pl-5 text-subtitle-2 primary--text" style="height: 29px">{{ item.text }}</td>
									</tr>
								</tbody>
							</v-simple-table>
						</frp-details-group>
					</v-card-text>
				</div>
			</template>
		</v-radio>
	</v-card>
</template>

<script>
import FrpDetailsGroup from "Components/details/FrpDetailsGroup";
import FrpDetailsItem from "Components/details/FrpDetailsItem";

export default {
	props: {
		certificate: {
			required: true
		},
		disabled: {
			type: Boolean
		},
		value: Number | String
	},
	computed: {
		items() {
			return [
				{ title: "Издатель", text: this.certificate.issuer },
				{ title: "Кому выдан", text: this.certificate.owner },
				{ title: "Действителен", text: this.certificate.validPeriod }
			]
		}
	},
	methods: {
		select() {
			this.$emit("select", this.certificate.thumbprint);
		}
	},
	components: { FrpDetailsItem, FrpDetailsGroup }
};
</script>

<style lang="less">
.frp-digital-signature-certificate {
	border: none;

	.v-radio {
		border: 1px solid transparent;

		&.v-item--active {
			border-color: var(--v-secondary-base);
		}

		i {
			font-size: 18px;
		}

		.v-input--selection-controls__input {
			margin-left: 20px;
		}
	}

	&:before {
		opacity: 0 !important;
	}

	& + & {
		margin-top: 6px !important;
	}
}

.frp-certificate-data-table {
	tr {
		background-color: var(--v-white-base) !important;
	}

	td {
		border-bottom: none !important;
	}
}
</style>

