import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { i18n, vuetify } from "./plugins";
import { sync } from "vuex-router-sync";
import "@/assets/main.css";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
import "reflect-metadata";

Vue.use(VueTelInputVuetify, {
	vuetify
});

sync(store, router);
Vue.config.productionTip = false;

new Vue({
	i18n,
	router,
	store,
	vuetify,
	render: (h) => h(App)
}).$mount("#app");
