import FrpAgreePersonalData from "Views/agreePersonalData/FrpAgreePersonalData.vue";
import FrpLayout from "Views/layouts/FrpLayout";
import FrpForgotPassword from "Views/forgotPassword/FrpForgotPassword";
import FrpResetPassword from "Views/resetPassword/FrpResetPassword";
import FrpSelectProfile from "Views/selectProfile/FrpSelectProfile";
import FrpSignIn from "Views/signIn/FrpSignIn";
import FrpSignUp from "Views/signUp/FrpSignUp";
import Vue from "vue";
import VueRouter from "vue-router";
import addSignInGuard from "Router/guards/signIn";

import routeNames from "Router/routeNames.ts";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: routeNames.base,
		component: FrpLayout,
		redirect: { name: routeNames.signIn },
		children: [
			{
				path: "sign-in",
				component: FrpSignIn,
				name: routeNames.signIn,
				meta: { requiresRedirectUri: true }
			},
			{
				path: "sign-up",
				component: FrpSignUp,
				name: routeNames.signUp,
				meta: { requiresRedirectUri: true }
			},
			{
				path: "forgot-password",
				component: FrpForgotPassword,
				name: routeNames.forgotPassword
			},
			{
				path: "reset-password",
				component: FrpResetPassword,
				name: routeNames.resetPassword
			},
			{
				path: "select-profile",
				component: FrpSelectProfile,
				name: routeNames.selectProfile
			},
			{
				path: "agree-personal-data",
				component: FrpAgreePersonalData,
				name: routeNames.agreePersonalData
			}
		]
	},
	{
		path: "*",
		redirect: { name: routeNames.signIn }
	}
];

const router = new VueRouter({
	routes,
	mode: "history"
});

addSignInGuard({ router });

export default router;
