<template>
	<frp-sign-in-form-base title="Регистрация">
		<v-responsive height="368">
			<v-form :readonly="isFormSaving || isFormReadonly" :disabled="isFormDisabled"
					@submit.prevent="submit"
					:ref="refs.form" v-model="formValid">
				<v-row>
					<v-col>
						<frp-text-field autocomplete="email"
										name="email"
										:placeholder="$t('placeholders.email')"
										label="Ваш email"
										:rules="validation.login"
										v-model="login">
						</frp-text-field>
						<frp-text-field autocomplete="new-password"
										name="password"
										@click:append="isPasswordHidden = !isPasswordHidden"
										:type="isPasswordHidden ? 'password' : 'text'"
										:append-icon="isPasswordHidden ? 'mdi-eye-off' : 'mdi-eye'"
										:placeholder="$t('placeholders.password')"
										label="Ваш пароль"
										:rules="validation.password"
										clearable
										class="mt-1"
										v-model="password">
						</frp-text-field>
						<frp-text-field autocomplete="new-password"
										@click:append="isConfirmedPasswordHidden = !isConfirmedPasswordHidden"
										:type="isConfirmedPasswordHidden ? 'password' : 'text'"
										:append-icon="isConfirmedPasswordHidden ? 'mdi-eye-off' : 'mdi-eye'"
										:placeholder="$t('placeholders.password')"
										label="Подтверждение пароля"
										:rules="validation.confirmedPassword"
										clearable
										class="mt-1"
										v-model="confirmedPassword">
						</frp-text-field>
					</v-col>
				</v-row>
				<v-row class="flex-column pb-1">
					<v-col>
						<v-btn class="frp-btn"
							   type="submit"
							   color="secondary"
							   :loading="isFormSaving"
							   :disabled="!formValid"
							   block>
							Зарегистрироваться
						</v-btn>
					</v-col>
					<v-col class="d-flex justify-center">
						<v-btn color="primary"
							   :disabled="isFormSaving"
							   :to="{ name: routeNames.signIn, query: $route.query }"
							   class="frp-btn"
							   block
							   outlined>
							Войти
						</v-btn>
					</v-col>
				</v-row>
			</v-form>
		</v-responsive>

		<template #actions>
			<v-col class="d-flex justify-center mt-9">
				<v-btn color="secondary"
					   :disabled="isFormSaving"
					   :href="`${baseUrl}/master-account-access`"
					   small
					   class="frp-btn"
					   plain
					   text>
					Зарегистрировать организацию
				</v-btn>
			</v-col>
		</template>
	</frp-sign-in-form-base>
</template>

<script>
import FrpTextField from "Components/fields/FrpTextField";
import FrpIcon from "Components/icon/FrpIcon";
import IcoSignUpSuccess from "Assets/img/icons/ico_sign-up-success.svg";
import FrpMessageBox from "Components/common/FrpMessageBox";
import formMixin from "@/mixins/formMixin";
import colorsMixin from "@/mixins/colorsMixin";
import routeNames from "Router/routeNames";
import storeManager from "@/store/manager";
import { mutationTypes, actionTypes } from "Store/modules/signUp/types";
import { prepareConfirmedPasswordRule, prepareEmailRule, preparePasswordRule, requiredRule } from "Utils/validation";
import FrpSignInFormBase from "Views/shared/FrpSignInFormBase";
import { createNamespacedHelpers } from "vuex";

const namespace = storeManager.signUp.namespace;
const { mapState, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [formMixin, colorsMixin],
	data() {
		return {
			baseUrl: process.env.VUE_APP_BASE_URL,
			routeNames,
			namespace,
			isPasswordHidden: true,
			isConfirmedPasswordHidden: true,
			validation: {
				login: [requiredRule(), prepareEmailRule()],
				password: [requiredRule(), preparePasswordRule(), prepareConfirmedPasswordRule(() => this.confirmedPassword)],
				confirmedPassword: [requiredRule(), prepareConfirmedPasswordRule(() => this.password)]
			}
		};
	},
	computed: {
		...mapState({
			user: state => state.user
		}),
		login: {
			get() {
				return this.user.login;
			},
			set(value) {
				this.setLogin(value);
			}
		},
		password: {
			get() {
				return this.user.password;
			},
			set(value) {
				this.setPassword(value);
			}
		},
		confirmedPassword: {
			get() {
				return this.user.confirmedPassword;
			},
			set(value) {
				this.setConfirmedPassword(value);
			}
		}
	},
	methods: {
		...mapActions({
			save: actionTypes.save
		}),
		...mapMutations({
			setLogin: mutationTypes.SET_LOGIN,
			setPassword: mutationTypes.SET_PASSWORD,
			setConfirmedPassword: mutationTypes.SET_CONFIRMED_PASSWORD
		})
	},
	watch: {
		password() {
			this.validate()
		},
		confirmedPassword() {
			this.validate()
		}
	},
	components: {
		FrpSignInFormBase,
		FrpIcon,
		FrpTextField,
		IcoSignUpSuccess,
		FrpMessageBox
	}
};
</script>
