<template>
	<a :href="href" class="frp-link secondary--text text-decoration-none">
		<slot></slot>
	</a>
</template>

<script>
export default {
	props: {
		href: {
			type: String,
			required: true
		}
	}
}
</script>

<style scoped lang="scss">
.frp-link {
	&:hover {
		opacity: 0.7;
	}
}
</style>
