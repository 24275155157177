import { MutationTypes, ActionTypes, GetterTypes, namespace } from "@/store/modules/resetPassword/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import FormMixinBuilder from "@/store/shared/form";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import ResetPasswordState from "@/store/modules/resetPassword/types/resetPasswordState";
import { ResetPasswordModeType } from "@/store/modules/resetPassword/types/resetPasswordModeType";
import { resetPassword } from "@/api/auth";

const formMixin = (new FormMixinBuilder()).build();
const baseMixin = (new BaseMixinBuilder()).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new ResetPasswordState(
			formMixin.state(),
			ResetPasswordModeType.INITIAL,
			""
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<ResetPasswordState, any>>{
	...formMixin.getters
};

const actions = <ActionTree<ResetPasswordState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	async [ActionTypes.save]({ commit, state }) {
		commit(MutationTypes.SET_IS_FORM_SAVING, true);

		try {
			await resetPassword(state.password);

			commit(MutationTypes.SET_MODE, ResetPasswordModeType.SUCCESS);
		} catch (error: any) {
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(MutationTypes.SET_IS_FORM_SAVING, false);
		}
	}
};

const mutations = <MutationTree<ResetPasswordState>>{
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	[MutationTypes.SET_MODE](state, value) {
		state.mode = value;
	},
	[MutationTypes.SET_PASSWORD](state, value) {
		state.password = value;
	}
};

export {
	namespace, state, getters, actions, mutations
};

const resetPasswordModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default resetPasswordModule;
