import FormModel from "@/store/shared/form/models/formModel";
import { ForgotPasswordModeType } from "@/store/modules/forgotPassword/types/forgotPasswordModeType";
import { Type } from "class-transformer";

export default class ForgotPasswordState {
	@Type(() => FormModel)
	form: FormModel;
	email: string;
	mode: ForgotPasswordModeType;

	constructor(form: FormModel, email: string, mode: ForgotPasswordModeType) {
		this.form = form;
		this.email = email;
		this.mode = mode;
	}
}
