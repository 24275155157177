import InvalidArgumentException from "@/exceptions/invalidArgumentException";
import {
	OID_CP_GOST_R3410_12_256,
	OID_CP_GOST_R3410_12_512,
	OID_CP_GOST_R3411_12_256_R3410,
	OID_CP_GOST_R3411_12_512_R3410
} from "@/constants/signatureIdentifiers";
import { Certificate } from "crypto-pro/lib/api/certificate";

const digitalSignatureAlgorithms = [OID_CP_GOST_R3410_12_256, OID_CP_GOST_R3410_12_512,
	OID_CP_GOST_R3411_12_256_R3410, OID_CP_GOST_R3411_12_512_R3410];

export const checkDigitalSignatureAlgorithm = async (certificate: Certificate) => {
	if(!certificate) throw new InvalidArgumentException("certificate", certificate);

	// @ts-ignore
	let { oid }: { oid: string } = await certificate.getAlgorithm();

	return digitalSignatureAlgorithms.includes(oid);
};
