<template>
	<div class="d-flex flex-column mb-4" v-if="value">
		<span class="text-caption">{{ title }}</span>
		<span class="text-body-2">{{ value }}</span>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			required: true
		},
		value: {
			type: String,
			required: true
		}
	},
	name: "FrpDetailsItem"
}
</script>

<style scoped>

</style>
