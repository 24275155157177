import { render, staticRenderFns } from "./FrpAlertsSnackbar.vue?vue&type=template&id=00cd8dfb"
import script from "./FrpAlertsSnackbar.vue?vue&type=script&lang=js"
export * from "./FrpAlertsSnackbar.vue?vue&type=script&lang=js"
import style0 from "./FrpAlertsSnackbar.vue?vue&type=style&index=0&id=00cd8dfb&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports