<template>
	<router-view/>
</template>

<script>
import "reflect-metadata";

export default {
	name: "App"
};
</script>
