export default class ApiProfile {
	id: string;
	title: string;
	isSignatureRequired: boolean;

	constructor(id: string, title: string, isSignatureRequired: boolean) {
		this.id = id;
		this.title = title;
		this.isSignatureRequired = isSignatureRequired;
	}
}
