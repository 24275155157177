import { Store } from "vuex";
import { resolveMutation } from "@/utils/vuexModules";
import alertTypes from "@/store/modules/alerts/types";

let store: Store<any>;

export const registerAlertService = (value: Store<any>) => {
	store = value;
};

export enum AlertKeys {
	OFFLINE_REQUEST_ERROR,
	SERVER_UNAVAILABLE_ERROR,
	REQUEST_UNKNOWN_ERROR,
	ACCOUNT_NOT_FOUND_ERROR,
	FORGOT_PASSWORD_ACCOUNT_NOT_FOUND_ERROR
}

const alertTextMap = {
	[AlertKeys.ACCOUNT_NOT_FOUND_ERROR]: "Неверный логин или пароль. Повторите попытку или нажмите на ссылку \"Восстановить доступ\"",
	[AlertKeys.FORGOT_PASSWORD_ACCOUNT_NOT_FOUND_ERROR]: "Пользователь с таким email не найден",
	[AlertKeys.REQUEST_UNKNOWN_ERROR]: "Возникла непредвиденная ошибка",
	[AlertKeys.SERVER_UNAVAILABLE_ERROR]: "Сервис временно недоступен. Повторите попытку позже",
	[AlertKeys.OFFLINE_REQUEST_ERROR]: "Отсутствует интернет-соединение. Проверьте подключение и повторите попытку"
};

class AlertService {
	addError(key: AlertKeys) {
		store.commit(resolveMutation(alertTypes.namespace, alertTypes.mutationTypes.ADD_ERROR_ALERT),
			alertTextMap[key], { root: true });
	}

	addInfo(key: AlertKeys) {
		store.commit(resolveMutation(alertTypes.namespace, alertTypes.mutationTypes.ADD_INFO_ALERT),
			alertTextMap[key], { root: true });
	}

	addCustomError(text: string) {
		store.commit(resolveMutation(alertTypes.namespace, alertTypes.mutationTypes.ADD_ERROR_ALERT),
			text, { root: true });
	}
}

export default new AlertService();
