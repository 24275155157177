<template>
	<frp-dialog :title="title" v-model="internalValue" persistent>
		<template v-slot:content="{ onIntersect }">
			<template v-if="isCertificatesInitializing">
				<frp-digital-signature-certificate-loader></frp-digital-signature-certificate-loader>
			</template>
			<template v-else-if="certificates.length">
				<v-radio-group v-model="selected" hide-details v-intersect="onIntersect" class="mt-0">
					<frp-digital-signature-certificate v-for="(certificate, i) of certificates"
													   :disabled="isSigning || isCertificateSelecting"
													   :certificate="certificate"
													   :value="i"
													   :key="i"
													   @select="selectCertificate">
					</frp-digital-signature-certificate>
				</v-radio-group>
			</template>
			<template v-else>
				<v-responsive height="220">
					<v-container class="fill-height">
						<v-row align="center">
							<v-col cols="12" class="d-flex justify-center align-center flex-column">
								<frp-icon src="ico_empty-data"></frp-icon>
								<span class="text-caption mt-3">Данные отсутствуют</span>
							</v-col>
						</v-row>
					</v-container>
				</v-responsive>
			</template>
		</template>

		<template #footer>
			<v-btn class="frp-btn mr-4"
				   v-if="!isCertificatesInitializing"
				   :disabled="isSigning || isCertificateSelecting"
				   outlined
				   @click="cancel"
				   color="primary">
				{{ cancelBtn || $t("buttons.cancel") }}
			</v-btn>
			<v-btn class="frp-btn"
				   v-if="!isCertificatesInitializing"
				   :loading="isSigning || isCertificateSelecting"
				   :disabled="!selectedCertificate"
				   @click="submit"
				   color="secondary">
				{{
					submitBtn || (mode === DigitalSignatureDialogModeType.SIGN ?
						$t("buttons.sign") :
						$t("buttons.choose"))
				}}
			</v-btn>
		</template>
	</frp-dialog>
</template>

<script>
import { getSignerCertificate } from "Api/cryptopro";
import FrpDigitalSignatureCertificate from "Components/digitalSignature/FrpDigitalSignatureCertificate";
import FrpDigitalSignatureCertificateLoader from "Components/digitalSignature/FrpDigitalSignatureCertificateLoader";
import FrpIcon from "Components/icon/FrpIcon";
import FrpDialog from "Components/dialogs/FrpDialog";
import { getUserCertificates } from "crypto-pro";
import { parseISO } from "date-fns";
import SignException from "Exceptions/signException";
import colorsMixin from "Mixins/colorsMixin";
import alertService from "Store/modules/alerts/services/alertService";
import FileMeta from "Store/shared/storage/types/fileMeta";
import { formatDateInterval } from "Utils/dates";
import { checkDigitalSignatureAlgorithm } from "Utils/digitalSignature";
import { prepareSignatureName, signFileWithDetachedSignature, signWithDetachedSignature } from "Utils/signature";
import { requiredRule } from "Utils/validation";


export const DigitalSignatureDialogModeType = {
	SIGN: "SIGN",
	SELECT_CERTIFICATE: "SELECT_CERTIFICATE"
};

export default {
	mixins: [colorsMixin],
	props: {
		dataUrl: {
			type: String
		},
		file: {
			type: File
		},
		mode: {
			default: DigitalSignatureDialogModeType.SIGN
		},
		signed: {
			type: Boolean,
			default: false
		},
		signature: String,
		description: String,
		cancelBtn: String,
		submitBtn: String,
		meta: {
			type: FileMeta
		},
		value: {
			type: Boolean,
			default: false
		},
		payload: {
			required: true
		}
	},
	data() {
		return {
			DigitalSignatureDialogModeType,
			validation: {
				certificates: [requiredRule()]
			},
			certificates: [],
			isSigning: false,
			isCertificateSelecting: false,
			isCertificatesInitializing: false,
			selected: undefined
		};
	},
	computed: {
		internalValue: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit("input", value);
			}
		},
		title() {
			if(this.isCertificatesInitializing)
				return "Обращение к электронной подписи";

			return "Выберите электронную подпись";
		},
		selectedCertificate() {
			return this.certificates.find(x => x.isSelected);
		}
	},
	methods: {
		async submit() {
			await this.sign();
		},
		async sign() {
			this.isSigning = true;

			try {
				let result = await signWithDetachedSignature(this.payload, this.selectedCertificate.thumbprint);

				this.$emit("signed", result);
			} catch (e) {
				console.error(e);
				if(e instanceof SignException)
					alertService.addCustomError(e.message);
			} finally {
				this.isSigning = false;
				this.closeDialog();
			}
		},
		selectCertificate(thumbprint) {
			for (let certificate of this.certificates) {
				certificate.isSelected = certificate.thumbprint === thumbprint;
			}
		},
		cancel() {
			this.$emit("cancel");
			this.closeDialog();
		},
		closeDialog() {
			this.$emit("input", false);
		},
		async initializeCertificates() {
			this.isCertificatesInitializing = true;

			try {
				let certificates = [];

				for await (let certificate of await getUserCertificates()) {
					if(!await checkDigitalSignatureAlgorithm(certificate))
						continue;

					// TODO Будут ошибки, решить после публикации собственной версии crypto-pro
					const DS_CERTIFICATE_ISSUER = "Удостоверяющий центр";
					const DS_CERTIFICATE_OWNER = "Владелец";

					let issuer = (await certificate.getIssuerInfo()).find(x => x.title === DS_CERTIFICATE_ISSUER);
					let owner = (await certificate.getOwnerInfo()).find(x => x.title === DS_CERTIFICATE_OWNER);

					certificates.push({
						thumbprint: certificate.thumbprint,
						isSelected: false,
						issuer: issuer.description,
						owner: owner.description,
						title: certificate.name,
						validPeriod: formatDateInterval(parseISO(certificate.validFrom), parseISO(certificate.validTo))
					});
				}

				this.certificates = certificates;
			} catch (error) {
				console.error(error);
			} finally {
				this.isCertificatesInitializing = false;
			}
		}
	},
	async created() {
		await this.initializeCertificates();
	},
	components: {
		FrpDigitalSignatureCertificate,
		FrpDigitalSignatureCertificateLoader,
		FrpIcon,
		FrpDialog
	}
};
</script>
