import AlertModel from "@/store/modules/alerts/models/alertModel";
import { Type } from "class-transformer";

export default class AlertsStateModel {
	@Type(() => AlertModel)
	errorAlerts: AlertModel[];
	@Type(() => AlertModel)
	infoAlerts: AlertModel[];

	constructor({ errorAlerts, infoAlerts }: { errorAlerts: AlertModel[], infoAlerts: AlertModel[] }) {
		this.errorAlerts = errorAlerts;
		this.infoAlerts = infoAlerts;
	}
}
