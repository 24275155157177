import { parse } from "date-fns";
import i18n from "@/plugins/i18n";
import { isNumber } from "lodash";
//@ts-ignore
import { checkINN } from "ru-validation-codes";
import { dateWithSecondsFormat, isDateWithSecondsFormatValid } from "@/utils/formats";
import { formatDate, parseDateToTime } from "@/utils/dates";
import validator from "is-my-date-valid";
import PasswordValidator from "password-validator";

// в данной репе нет этих библиотек
// import isValidIp from "validate-ip";
// import isValidDomain from "is-valid-domain";

const passwordValidator = new PasswordValidator();
passwordValidator.is().min(8, "Не менее 8 символов").is().max(100, "Не более 100 символов");

const validateDateTime = validator({ format: isDateWithSecondsFormatValid });

export const requiredRule = () => {
	return (v: string) => v !== "" && !/^[\s]+$/.test(v) || i18n.t("validation.required");
};

export const prepareInnRule = () => {
	return (v: any) => v === "" || checkINN(v) || i18n.t("validation.inn");
};

export const preparePasswordRule = () => {
	return (v: string) => {
		let result = passwordValidator.validate(v, { details: true }) as { message: string }[];
		return result.length ? result[0].message : true;
	};
};

export const prepareMaxLengthRule = ({ maxLength }: { maxLength: number }) => {
	return (v: string | any[]) => !v || v.length <= maxLength ||
		`${i18n.t("validation.nameNoMore")} ${maxLength} ${i18n.t("validation.symbols")}`;
};

export const prepareConfirmedPasswordRule = (getPassword: Function) => {
	return (v: string) => !v || !getPassword() || getPassword() === v || "Пароли не совпадают";
};

export const validDate = () => {
	return (v: string) => !v || validateDateTime(v) || i18n.t("validation.invalidDate");
};

export const prepareMaxNumbersRule = ({ getMax }: { getMax: any }) => {
	return (v: string | number) => v === "" || v <= getMax() || i18n.t("validation.maxNumber", { number: getMax() });
};

export const prepareMinNumbersRule = ({ getMin }: { getMin: any }) => {
	return (v: string | number) => v === "" || v >= getMin() || i18n.t("validation.minNumber", { number: getMin() });
};


export const prepareMaxDateRule = ({ getMax, format }: { getMax: any, format: string }) => {
	return (v: string) => !getMax() || !v || parse(v, format, new Date()) <= getMax() ||
		i18n.t("validation.maxDate", { number: formatDate(getMax(), format) });
};

export const prepareMinDateRule = ({ getMin, format }: { getMin: any, format: string }) => {
	return (v: string) => !getMin() || !v || parse(v, format, new Date()) >= getMin() ||
		i18n.t("validation.minDate", { number: formatDate(getMin(), format) });
};

// export const prepareIpAddressRule = () => {
// 	return (v: string | number) => !v || isValidIp(v) ||
// 		i18n.t("validation.validIpAddress");
// };

export const prepareEmailRule = () => {
	return (v: string) => !v || /.+@.+\..+/i.test(v) || i18n.t("validation.validEmail");
};

export const prepareEmailTempRule = () => {
	return (v: string) => !v || /.+@.+\..+/i.test(v) || v === "admin" || i18n.t("validation.validEmail");
};

export const prepareConfirmedEmailRule = ({ getEmail }: { getEmail: any }) => {
	return (v: any) => !v || getEmail() === v || i18n.t("validation.validConfirmedEmail");
};

// export const prepareIpOrServerAddressRule = () => {
// 	return (v: string) => !v || isValidIp(v) || isValidDomain(v, { allowUnicode: true }) || i18n.t("validation.validIpAddress");
// };

export const onlyIntegerNumbersRule = () => {
	return (v: string) => !v || /^[-\d]+$/.test(v) || i18n.t("validation.validOnlyIntegerNumbers");
};

export const onlyNumbersRule = () => {
	return (v: string)  => !v || /^[-\d\.]+$/.test(v) || i18n.t("validation.validOnlyNumbers");
};

export const onlyLatinRule = () => {
	return (v: string)  => !v || /^[a-zA-Z\s]+$/.test(v) || i18n.t("validation.validOnlyLatin");
};

/* Временные проверки */
export const passwordRule = () => {
	return (v: string | string[]) => !v || v.includes("test") || i18n.t("validation.validPassword");
};

export const maxLengths = {
	search: 50,
	short: 50,
	middle: 100,
	long: 500
};
