export const dateFormat = "dd.MM.yyyy";
export const friendlyDateFormat = "dd LLLL yyyy";
export const friendlyDateFormatWithoutYear = "dd LLLL";
export const dateFormatUpperCase = "DD.MM.YYYY";
export const dateTimeFormat = "dd.MM.yyyy HH:mm";
export const dateWithSecondsFormat = "dd.MM.yyyy HH:mm:ss";
export const isDateWithSecondsFormatValid = "DD.MM.YYYY HH:mm:ss";
export const isoDateFormat = "yyyy-MM-dd";
export const timeFormat = "HH:mm";
export const timeFormatWithSeconds = "hh:mm:ss";
export const timeFormatMinutesWithSeconds = "mm:ss";
export const isMyDateValidDateFormat = "DD.MM.YYYY";
export const dayOfWeek = "EEEEEE"
export const numberDayOfWeek = "i"

