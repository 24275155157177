<template>
	<frp-sign-in-form-base :title="$t('titles.welcome')">
		<v-form :readonly="isFormSaving || isFormReadonly" :disabled="isFormDisabled"
				@submit.prevent="submit"
				:ref="refs.form" v-model="formValid">
			<v-row>
				<v-col>
					<frp-text-field
						:tooltip-text="$t('tooltips.signIn')"
						autocomplete="username"
						name="username"
						:placeholder="$t('fields.login.placeholder')"
						:label="$t('fields.login.label')"
						:rules="validation.login"
						v-model="login">
					</frp-text-field>
					<frp-text-field autocomplete="current-password"
									name="password"
									hide-details
									@click:append="isPasswordVisible = !isPasswordVisible"
									:type="isPasswordVisible ? 'text' : 'password'"
									:append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
									:placeholder="$t('fields.password.placeholder')"
									:label="$t('fields.password.label')"
									:rules="validation.password"
									v-model="password">
					</frp-text-field>
				</v-col>
			</v-row>
			<v-row class="mt-5">
				<v-col class="d-flex pt-0">
					<frp-link-btn :color="colors.secondary.base"
								  icon
								  :href="helpAuthorization"
								  target="_blank">
						<template #icon>
							<doc-info-icon></doc-info-icon>
						</template>
						<template #content>
							{{ $t('common.help') }}
						</template>
					</frp-link-btn>
				</v-col>
				<v-col class="d-flex pt-0 justify-end">
					<v-btn color="primary"
						   small
						   :to="{ name: routeNames.forgotPassword, query: $route.query }"
						   class="frp-btn text-none pa-0 text-caption text-decoration-underline"
						   plain
						   text>
						{{ $t("buttons.restoreAccess") }}
					</v-btn>
				</v-col>
			</v-row>
			<v-row class="flex-column">
				<v-col>
					<v-btn class="frp-btn"
						   type="submit"
						   :loading="isFormSaving"
						   color="secondary"
						   :disabled="!formValid"
						   block>
						{{ $t("buttons.login") }}
					</v-btn>
				</v-col>

				<v-col class="d-flex justify-center pt-4">
					<v-btn color="secondary"
						   :href="`${baseUrl}/master-account-access`"
						   small
						   class="frp-btn"
						   plain
						   text>
						{{ $t("buttons.register") }}
					</v-btn>
				</v-col>
			</v-row>
		</v-form>
	</frp-sign-in-form-base>
</template>

<script>
import FrpLinkBtn from "@/components/buttons/FrpLinkBtn.vue";
import colorsMixin from "@/mixins/colorsMixin";
import FrpTextField from "Components/fields/FrpTextField";
import DocInfoIcon from "Assets/img/icons/ico_doc-info.svg";
import FrpIcon from "Components/icon/FrpIcon";
import formMixin from "Mixins/formMixin";
import routeNames from "Router/routeNames";
import storeManager from "Store/manager";
import { actionTypes, mutationTypes } from "Store/modules/signIn/types";

import { prepareEmailTempRule, requiredRule } from "Utils/validation";
import FrpSignInFormBase from "Views/shared/FrpSignInFormBase";
import { createNamespacedHelpers } from "vuex";

const namespace = storeManager.signIn.namespace;
const { mapState, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [formMixin, colorsMixin],
	data() {
		return {
			baseUrl: process.env.VUE_APP_BASE_URL,
			namespace,
			routeNames,
			isPasswordVisible: false,
			validation: {
				login: [requiredRule()],
				password: [requiredRule()]
			},
			helpAuthorization: "https://lkfrprf.bitrix24.site/registration/"
		};
	},
	computed: {
		...mapState({
			user: state => state.user
		}),
		login: {
			get() {
				return this.user.login;
			},
			set(value) {
				this.setLogin(value);
			}
		},
		password: {
			get() {
				return this.user.password;
			},
			set(value) {
				this.setPassword(value);
			}
		}
	},
	methods: {
		...mapMutations({
			setLogin: mutationTypes.SET_LOGIN,
			setPassword: mutationTypes.SET_PASSWORD
		}),
		...mapActions({
			initializeStore: actionTypes.initialize,
			save: actionTypes.save
		})
	},
	created() {
		this.initializeStore();
	},
	components: {
		FrpLinkBtn,
		DocInfoIcon,
		FrpSignInFormBase,
		FrpIcon,
		FrpTextField
	}
};
</script>
