<template>
	<v-snackbar v-model="isShown" elevation="0" :color="color" :timeout="timeout"
				:absolute="absolute" :right="right">
		<div class="d-flex align-center">
			<frp-icon v-if="!error" class="mr-3" src="ico_check-circle"></frp-icon>
			{{ text }}
		</div>
		<template v-slot:action="{ attrs }">
			<v-btn icon
				   v-if="closable"
				   v-bind="attrs"
				   @click="close">
				<template>
					<v-icon>mdi-close</v-icon>
				</template>
			</v-btn>
		</template>
	</v-snackbar>
</template>

<script>
import FrpIcon from "Components/icon/FrpIcon";

export default {
	props: {
		color: String,
		text: String,
		timeout: Number,
		closable: {
			default: true
		},
		absolute: {
			default: false
		},
		right: {
			type: Boolean,
			default: false
		},
		error: {
			default: false
		}
	},
	data() {
		return {
			isShown: true
		};
	},
	watch: {
		isShown(newValue) {
			if (!newValue) {
				this.close();
			}
		}
	},
	methods: {
		close() {
			this.$emit("close");
		}
	},
	components: {
		FrpIcon
	}
};
</script>

<style lang="less">
.v-snack__wrapper {
	min-width: unset;
}
</style>
