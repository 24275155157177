<template>
	<v-dialog v-resize="resizeScrollableCard" ref="dialog" :persistent="persistent"
			  :value="value" @input="$emit('update:value', $event)"
			  :max-width="maxWidth" content-class="overflow-hidden">
		<template v-slot:activator="{ on, attrs }">
			<slot name="activator" :on="on" :attrs="attrs"></slot>
		</template>

		<v-card class="d-flex align-start flex-wrap overflow-hidden" elevation="0">
			<v-container ref="dialogCard" fluid class="px-6 py-0 overflow-y-auto frp-scrollbar">
				<v-row class="my-0 white px-3" style="position: sticky; top: 0; z-index: 100">
					<v-col class="px-0">
						<p class="text-h6 primary--text text--darken-1 mt-3 d-flex justify-space-between">
							<slot name="title">
								{{ title }}
							</slot>
						</p>
					</v-col>
				</v-row>

				<v-row class="my-0">
					<v-col class="pt-0">
						<slot :onIntersect="resizeScrollableCard" name="content"></slot>
					</v-col>
				</v-row>

				<v-row class="my-0 white" style="position: sticky; bottom: 0">
					<v-col>
						<v-responsive content-class="d-flex justify-end py-3">
							<slot name="footer"></slot>
						</v-responsive>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
	</v-dialog>
</template>

<script>

export default {
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean,
		title: String,
		persistent: Boolean,
		maxWidth: {
			default: 640
		}
	},
	methods: {
		resizeScrollableCard() {
			if(this.$refs.dialogCard) {
				const height = window.innerHeight;
				this.$refs.dialogCard.style.maxHeight = "100%";

				if(height)
					this.$refs.dialogCard.style.maxHeight = `${height - 100}px`;
			}
		}
	}
};
</script>
