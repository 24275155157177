<template>
	<div>
		<div class="d-flex">
			<v-subheader v-if="label" class="pa-0 mb-1 grey--text text-caption d-flex justify-space-between"
						 style="height: 16px; width: 100%">
				<span>{{ label }}</span>
				<template v-if="tooltipText">
					<frp-information-menu :text="tooltipText"/>
				</template>
			</v-subheader>
		</div>
		<v-text-field outlined
					  :readonly="readonly"
					  ref="input"
					  :background-color="colors.white.base"
					  @input="$emit('update:value', $event)"
					  :type="type"
					  v-on="$listeners"
					  :value="value"
					  :error-messages="errors"
					  dense
					  :autocomplete="autocomplete"
					  :name="name"
					  :append-icon="appendIcon"
					  v-bind="$attrs"
					  class="frp-text-field text-body-2"
					  :disabled="disabled"
					  :hide-details="hideDetails">
		</v-text-field>
	</div>
</template>

<script>
import FrpInformationMenu from "@/components/common/FrpInformationMenu.vue";
import FrpIcon from "Components/icon/FrpIcon";
import colorsMixin from "Mixins/colorsMixin";

export default {
	inheritAttrs: false,
	mixins: [colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		readonly: Boolean,
		value: String,
		type: String,
		label: String,
		disabled: Boolean,
		clearable: Boolean,
		hideDetails: Boolean,
		icon: String,
		appendIcon: String,
		errors: {
			type: Array | String,
			default: () => []
		},
		tooltipText: String,
		autocomplete: String,
		name: String
	},
	components: {
		FrpInformationMenu,
		FrpIcon
	}
};
</script>
