import { format, addMinutes, parse } from "date-fns";
import { ru } from "date-fns/locale";
import { dateFormat, dateTimeFormat, friendlyDateFormat, dayOfWeek, timeFormat, friendlyDateFormatWithoutYear } from "@/utils/formats";
import { capitalizeFirstLetter } from "@/utils/formatting";

export const eachDateOfInterval = (
	{ start, end, interval }: {
		start: number | string | Date,
		end: number | string | Date,
		interval: number
	}) => {
	const dates = [];

	let currentDate = new Date(start);
	let endTime = new Date(end).getTime();

	while (currentDate.getTime() <= endTime) {
		dates.push(currentDate);
		currentDate = addMinutes(currentDate, interval);
	}

	return dates;
};

export const parseDateToTime = (str: string, format: string) => {
	return parse(str, format, new Date()).getTime();
};

export const formatInterval = (start: number | Date, end: number | Date) => {
	let timeInterval = `${formatDate(start, timeFormat)} - ${formatDate(end, timeFormat)}`;
	return `${formatDate(start, dateFormat)} ${timeInterval}`;
};

export const parseInterval = (strInterval: string) => {
	let segments = strInterval.split(" ");
	let [date, startTime] = segments;
	let [endTime] = segments.reverse();

	return {
		start: parseDateToTime(`${date} ${startTime}`, dateTimeFormat),
		end: parseDateToTime(`${date} ${endTime}`, dateTimeFormat)
	};
};

export const formatIntervalWeek = (start: number | Date, end: number | Date) => {
	return `${formatDate(start, friendlyDateFormatWithoutYear)} - ${formatDate(end, friendlyDateFormat)}`;
};


export const formatDate = (date: number | Date, dateFormat: string) => {
	return date && format(date, dateFormat, { locale: ru });
};


export const formatDateToDayOfWeek = (date: number | Date) => {
	return date && capitalizeFirstLetter(format(date, dayOfWeek, { locale: ru }));
};

export const formatDateInterval = (start:  number | Date, end:  number | Date) => {
	return `с ${formatDate(start, dateFormat)} по ${formatDate(end, dateFormat)}`;
};


export const removeTime = (date: any) => {
	return new Date(date.setHours(0, 0, 0, 0));
};
