<template>
	<v-alert v-if="!isClosed" :color="backgroundColor" class="rounded-lg text-body-2 frp-alert py-3"
			 :style="{ color: `${color} !important` }">
		<slot></slot>
		<v-btn v-if="closable" class="ml-4" small icon :style="{ color: `${color}` }" @click="isClosed = true">
			<v-icon>mdi-close</v-icon>
		</v-btn>
		<template #prepend>
			<frp-icon :color="color" class="mr-3">
				<ico-information></ico-information>
			</frp-icon>
		</template>
	</v-alert>
</template>

<script>
import FrpIcon from "Components/icon/FrpIcon";
import IcoInformation from "Assets/img/icons/ico_information.svg";
import colorsMixin from "Mixins/colorsMixin";

export default {
	mixins: [colorsMixin],
	props: {
		type: {
			type: String,
			default: "info"
		},
		closable: Boolean
	},
	data() {
		return {
			isClosed: false
		}
	},
	computed: {
		backgroundColor() {
			switch (this.type) {
				case "info":
					return this.colors.white.darken1;
				case "error":
					return this.colors.secondary.lighten1;
			}
		},
		color() {
			switch (this.type) {
				case "info":
					return "var(--v-grey-base)";
				case "error":
					return "var(--v-error-base)";
			}
		}
	},
	components: {
		FrpIcon,
		IcoInformation
	}
};
</script>

<style scoped>
.frp-alert {
	width: fit-content;
}
</style>
