const routeNames = {
	base: "base",
	signIn: "sign-in",
	forgotPassword: "forgot-password",
	resetPassword: "reset-password",
	signUp: "sign-up",
	selectProfile: "select-profile",
	agreePersonalData: "agree-personal-data"
};

export default routeNames;
