export const resolveAction = (namespace: string, action: string) => {
	const separator = "/";
	return [namespace, action].join(separator);
};

export const resolveNestedNamespace = (...segments: string[]) => {
	const separator = "/";
	return segments.join(separator);
};

export const splitNamespace = (namespace: string) => {
	const separator = "/";
	return namespace.split(separator);
};

export const resolveNestedState = <T>(state: any, namespace: string): T => {
	let current = state;

	for (const namespaceSegment of splitNamespace(namespace)) {
		current = current[namespaceSegment];
	}

	return current;
};

export const resolveCustomNestedNamespace = (param: string, ...segments: string[]) => {
	const separator = "/";
	return resolveCustomNamespaceSegment(segments.join(separator), param);
};

export const resolveCustomNamespaceSegment = (namespace: string, param: string) => {
	return `${namespace}-${param}`;
};

export const resolveMutation = (namespace: string, mutation: string) => {
	const separator = "/";
	return [namespace, mutation].join(separator);
};

export const resolveGetter = (namespace: string, getter: string) => {
	const separator = "/";
	return [namespace, getter].join(separator);
};

export const resolveComponentNamespace = (ctx: { namespace: string }) => ctx.namespace;
