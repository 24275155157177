<template>
	<frp-sign-in-form-base title="Установка нового пароля">
		<v-responsive class="py-2" v-if="mode === ResetPasswordModeType.INITIAL">
			<v-form :readonly="isFormSaving || isFormReadonly" :disabled="isFormDisabled"
					@submit.prevent="submit"
					:ref="refs.form" v-model="formValid">
				<v-row>
					<v-col class="pt-1">
						<frp-text-field autocomplete="new-password"
										name="password"
										@click:append="isPasswordHidden = !isPasswordHidden"
										:type="isPasswordHidden ? 'password' : 'text'"
										:append-icon="isPasswordHidden ? 'mdi-eye-off' : 'mdi-eye'"
										:placeholder="$t('placeholders.password')"
										label="Ваш новый пароль"
										:rules="validation.password"
										clearable
										v-model="password">
						</frp-text-field>
						<frp-text-field autocomplete="new-password"
										@click:append="isConfirmedPasswordHidden = !isConfirmedPasswordHidden"
										:type="isConfirmedPasswordHidden ? 'password' : 'text'"
										:append-icon="isConfirmedPasswordHidden ? 'mdi-eye-off' : 'mdi-eye'"
										:placeholder="$t('placeholders.password')"
										label="Повторите пароль"
										:rules="validation.confirmedPassword"
										clearable
										v-model="confirmedPassword">
						</frp-text-field>
					</v-col>
				</v-row>
				<v-row class="flex-column">
					<v-col class="pt-2">
						<v-btn class="frp-btn"
							   type="submit"
							   color="primary"
							   :loading="isFormSaving"
							   :disabled="!formValid"
							   block>
							Подтвердить
						</v-btn>
					</v-col>
				</v-row>
			</v-form>
		</v-responsive>

		<template #actions v-if="mode === ResetPasswordModeType.INITIAL">
			<v-col class="d-flex justify-center mt-8 px-10">
				<v-btn color="primary"
					   :to="routeNames.signIn"
					   :disabled="isFormSaving"
					   block
					   large
					   outlined
					   class="frp-btn">
					На форму авторизации
				</v-btn>
			</v-col>
		</template>

		<div v-else class="d-flex flex-column">
			<frp-icon :color="colors.grey.base" class="justify-center mt-2 mb-10">
				<ico-reset-password-success></ico-reset-password-success>
			</frp-icon>

			<div class="text-body-1 primary--text text--darken-1 text-center mx-auto">
				Пароль успешно изменен.
			</div>
			<div class="text-body-1 primary--text text--darken-1 text-center mx-auto">
				Перейдите к авторизации, чтобы войти в систему
			</div>

			<v-btn class="frp-btn mt-16" block color="primary" :to="routeNames.signIn">
				<frp-icon :color="colors.white.base" class="mr-2">
					<ico-arrow-left></ico-arrow-left>
				</frp-icon>
				К авторизации
			</v-btn>
		</div>
	</frp-sign-in-form-base>
</template>

<script>
import FrpTextField from "Components/fields/FrpTextField";
import FrpIcon from "Components/icon/FrpIcon";
import IcoResetPasswordSuccess from "Assets/img/icons/ico_reset-password-success.svg";
import IcoArrowLeft from "Assets/img/icons/ico_arrow-left.svg";
import formMixin from "@/mixins/formMixin";
import colorsMixin from "@/mixins/colorsMixin";
import routeNames from "Router/routeNames";
import storeManager from "@/store/manager";
import { MutationTypes, ActionTypes } from "Store/modules/resetPassword/types";
import { ResetPasswordModeType } from "Store/modules/resetPassword/types/resetPasswordModeType";
import { prepareConfirmedPasswordRule, preparePasswordRule, requiredRule } from "Utils/validation";
import FrpSignInFormBase from "Views/shared/FrpSignInFormBase";
import { createNamespacedHelpers } from "vuex";

const namespace = storeManager.resetPassword.namespace;
const { mapState, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [formMixin, colorsMixin],
	data() {
		return {
			ResetPasswordModeType: ResetPasswordModeType,
			routeNames,
			namespace,
			isPasswordHidden: true,
			confirmedPassword: "",
			isConfirmedPasswordHidden: true,
			validation: {
				login: [requiredRule()],
				password: [requiredRule(), preparePasswordRule()],
				confirmedPassword: [requiredRule(), prepareConfirmedPasswordRule(() => this.password)]
			}
		};
	},
	computed: {
		...mapState({
			state: state => state
		}),
		password: {
			get() {
				return this.state.password;
			},
			set(value) {
				this.setPassword(value);
			}
		},
		mode: {
			get() {
				return this.state.mode;
			},
			set(value) {
				this.setMode(value);
			}
		}
	},
	methods: {
		...mapMutations({
			setMode: MutationTypes.SET_MODE,
			setPassword: MutationTypes.SET_PASSWORD
		}),
		...mapActions({
			resetPassword: ActionTypes.save
		}),
		async save() {
			await this.resetPassword();
			this.mode = ResetPasswordModeType.SUCCESS;
		}
	},
	components: {
		FrpSignInFormBase,
		FrpIcon,
		FrpTextField,
		IcoResetPasswordSuccess,
		IcoArrowLeft
	}
};
</script>
