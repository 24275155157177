import { appsPath } from "@/constants/appsPath";

const baseUrl = process.env.VUE_APP_BASE_URL;

const addSignInGuard = ({ router }: { router: any }) => {
	router.beforeEach((to: any, from: any, next: any) => {
		const requiresRedirectUri = to.matched.some((record: any) => record.meta.requiresRedirectUri);

		if(requiresRedirectUri && !to.query.redirect_uri) {
			// @ts-ignore
			window.location = `${baseUrl}/${appsPath}`;
		} else {
			next();
		}
	});
};

export default addSignInGuard;
