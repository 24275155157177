export default class FormModel {
	isLoadingState: boolean;
	isSaving: boolean;
	isDisabled: boolean;
	isValid: boolean;
	isInitialized: boolean;
	isReadonly: boolean;

	constructor({
		isLoadingState,
		isSaving,
		isDisabled,
		isValid,
		isInitialized,
		isReadonly
	}: { isLoadingState: boolean, isSaving: boolean, isDisabled: boolean, isValid: boolean, isInitialized: boolean, isReadonly: boolean })
	{
		this.isLoadingState = isLoadingState;
		this.isSaving = isSaving;
		this.isDisabled = isDisabled;
		this.isValid = isValid;
		this.isInitialized = isInitialized;
		this.isReadonly = isReadonly;
	}
}
