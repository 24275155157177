import urls from "@/api/config/urls";
import { stringify } from "qs";

export const prepareUrlQuery = (obj: object) => {
	if(!obj) return "";

	let query = stringify(obj);

	return query ? `?${query}` : query;
};

export const prepareUrl = (path: string, params?: object) => {
	return params ? `${path}${prepareUrlQuery(params)}` : path;
};
