<template>
	<frp-sign-in-form-base title="Выбор профиля">
		<div class="text-caption primary--text text--darken-1 mx-auto mt-1 mb-4">
			Выберите необходимый профиль для работы в системе:
		</div>

		<v-row>
			<v-col>
				<v-menu bottom
						offset-y
						ref="menu"
						max-width="250"
						content-class="elevation-5 menu-transition">
					<template v-slot:activator="{ on, attrs }">
						<v-btn block
							   class="text-none profile-btn text-left justify-space-between profiles-menu mr-7 pa-3 primary--text text--darken-1"
							   v-ripple="false"
							   v-bind="attrs"
							   v-on="on" text outlined>
							<div class="d-flex flex-column flex-shrink-1" style="gap: 4px; overflow: hidden">
								<span class="text-subtitle-1 text-truncate">
									{{ selectedProfile.title }}
								</span>
							</div>
							<ico-chevron-down class="ml-2 flex-shrink-0"></ico-chevron-down>
						</v-btn>
					</template>
					<v-list class="py-2 profiles-list">
						<v-list-item-group>
							<v-list-item v-for="profile in profiles" :key="profile.id" @click="selectedProfile = profile">
								<div class="d-flex flex-column align-start" style="max-width: inherit">
									<span class="text-subtitle-1 py-2">
										{{ profile.title }}
									</span>
								</div>
							</v-list-item>
						</v-list-item-group>
					</v-list>
				</v-menu>
			</v-col>
		</v-row>
		<v-row class="flex-column">
			<v-col class="pt-0">
				<v-btn class="frp-btn mt-6"
					   @click="handleSubmit"
					   type="submit"
					   :loading="isSaving"
					   color="secondary"
					   block>
					Войти
				</v-btn>
			</v-col>
		</v-row>

		<frp-digital-signature-dialog @signed="handleSign"
									  v-if="dialog"
									  :payload="dataToBeSigned"
									  v-model="dialog"
									  :mode="DigitalSignatureDialogModeType.SELECT_CERTIFICATE">
		</frp-digital-signature-dialog>
	</frp-sign-in-form-base>
</template>

<script>
import FrpDigitalSignatureDialog, { DigitalSignatureDialogModeType } from "Components/digitalSignature/FrpDigitalSignatureDialog";
import FrpSignInFormBase from "Views/shared/FrpSignInFormBase";
import IcoArrowLeft from "Assets/img/icons/ico_arrow-left.svg";
import IcoChevronDown from "Assets/img/icons/ico_chevron-down.svg";
import FrpIcon from "Components/icon/FrpIcon";
import colorsMixin from "@/mixins/colorsMixin";
import routeNames from "Router/routeNames";
import storeManager from "@/store/manager";
import { actionTypes, mutationTypes } from "Store/modules/selectProfile/types";
import { createNamespacedHelpers } from "vuex";

const namespace = storeManager.selectProfile.namespace;
const { mapState, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin],
	data() {
		return {
			namespace,
			routeNames,
			selectedProfile: {},
			dialog: false,
			DigitalSignatureDialogModeType
		};
	},
	computed: {
		...mapState({
			profiles: state => state.profiles,
			isSaving: state => state.isSaving,
			isLoading: state => state.isLoading
		}),
		selectedProfileId() {
			return this.selectedProfile.id;
		},
		dataToBeSigned() {
			return {
				profileId: this.selectedProfileId,
				signedAt: Math.round(Date.now() / 1000)
			};
		}
	},
	methods: {
		...mapActions({
			initialize: actionTypes.initialize,
			submit: actionTypes.submit
		}),
		handleSign(payload) {
			this.submit({
				id: this.selectedProfileId,
				payload
			});
		},
		handleSubmit() {
			if(this.selectedProfile.isSignatureRequired) {
				this.dialog = true;
			} else {
				this.submit({ id: this.selectedProfileId });
			}
		}
	},
	async created() {
		await this.initialize();

		if(!this.profiles.length) {
			window.location = process.VUE_APP_BASE_URL;
		}
		this.selectedProfile = this.profiles[0];
	},
	components: {
		FrpDigitalSignatureDialog,
		FrpSignInFormBase,
		IcoArrowLeft,
		FrpIcon,
		IcoChevronDown
	}
};
</script>

<style lang="scss">
.profile-btn {
	height: auto !important;
	border-color: var(--v-grey-base) !important;
	max-width: 100% !important;

	.v-btn__content {
		max-width: inherit !important;
	}
}

.profiles-list {
	.v-list-item {
		max-width: inherit !important;

		.text-truncate {
			max-width: inherit !important;
		}
	}
}
</style>
