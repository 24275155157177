// @ts-ignore
import colors from "vuetify/lib/util/colors";
import Vue from "vue";

const colorsMixin = Vue.extend({
	computed: {
		colors() {
			return {
				...colors,
				primary: this.$vuetify.theme.currentTheme.primary,
				secondary: this.$vuetify.theme.currentTheme.secondary,
				white: this.$vuetify.theme.currentTheme.white,
				grey: this.$vuetify.theme.currentTheme.grey,
				blue: this.$vuetify.theme.currentTheme.blue,
				green: this.$vuetify.theme.currentTheme.green,
				accent: this.$vuetify.theme.currentTheme.accent,
				error: this.$vuetify.theme.currentTheme.error,
				success: this.$vuetify.theme.currentTheme.success,
				warning: this.$vuetify.theme.currentTheme.warning
			};
		}
	}
});

export default colorsMixin;
