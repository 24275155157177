import FormModel from "@/store/shared/form/models/formModel";
import SignInUser from "@/store/modules/signIn/types/signInUser";
import ApiProfile from "@/api/types/auth/apiProfile";
import { Type } from "class-transformer";

export default class SignInState {
	@Type(() => FormModel)
	form: FormModel;
	@Type(() => SignInUser)
	user: SignInUser;
	@Type(() => ApiProfile)
	profiles: ApiProfile[];

	redirectUri: string;

	constructor(form: FormModel, user: SignInUser, profiles: ApiProfile[], redirectUri: string) {
		this.form = form;
		this.user = user;
		this.profiles = profiles;
		this.redirectUri = redirectUri;
	}
}
