import { MutationTypes, ActionTypes, GetterTypes, namespace } from "@/store/modules/forgotPassword/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import FormMixinBuilder from "@/store/shared/form";
import HttpNotFoundException from "@/exceptions/httpNotFoundException";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import ForgotPasswordState from "@/store/modules/forgotPassword/types/forgotPasswordState";
import { ForgotPasswordModeType } from "@/store/modules/forgotPassword/types/forgotPasswordModeType";
import { forgotPassword } from "@/api/auth";
import ApiForgotPasswordParameters from "@/api/types/auth/apiForgotPasswordParameters";

const formMixin = (new FormMixinBuilder()).build();
const baseMixin = (new BaseMixinBuilder()).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new ForgotPasswordState(
			formMixin.state(),
			"",
			ForgotPasswordModeType.INITIAL
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<ForgotPasswordState, any>>{
	...formMixin.getters
};

const actions = <ActionTree<ForgotPasswordState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	async [ActionTypes.save]({ commit, state }) {
		commit(MutationTypes.SET_IS_FORM_SAVING, true);

		try {
			await forgotPassword(new ApiForgotPasswordParameters(state.email));

			commit(MutationTypes.SET_MODE, ForgotPasswordModeType.SUCCESS);
		} catch (error: any) {
			switch (error.constructor) {
				case HttpNotFoundException:
					break;
				default:
					AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(MutationTypes.SET_IS_FORM_SAVING, false);
		}
	}
};

const mutations = <MutationTree<ForgotPasswordState>>{
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	[MutationTypes.SET_EMAIL](state, value) {
		state.email = value;
	},
	[MutationTypes.SET_MODE](state, value) {
		state.mode = value;
	}
};

export {
	namespace, state, getters, actions, mutations
};

const forgotPasswordModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default forgotPasswordModule;
