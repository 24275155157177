import { actionTypes, mutationTypes, namespace } from "@/store/modules/signUp/types";
import BaseMixinBuilder from "@/store/shared/base/index";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import FormMixinBuilder from "@/store/shared/form";
import SignUpState from "@/store/modules/signUp/types/signUpState";
import User from "@/store/modules/signUp/types/user";
import { authorize, login, registerUser } from "@/api/auth";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import ApiCreateUser from "@/api/types/auth/apiCreateUser";
import ApiLoginUser from "@/api/types/auth/apiLoginUser";

const formMixin = (new FormMixinBuilder()).build();
const baseMixin = (new BaseMixinBuilder()).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new SignUpState(
			formMixin.state(),
			new User("", "", "")
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<SignUpState, any>>{
	...formMixin.getters
};

const actions = <ActionTree<SignUpState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	async [actionTypes.save]({ commit, state }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		try {
			await registerUser(new ApiCreateUser(state.user.login, state.user.password));

			const { redirectUri } = await login(new ApiLoginUser(state.user.login, state.user.password));

			window.location = await authorize(redirectUri);
		} catch (error: any) {
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	}
};

const mutations = <MutationTree<SignUpState>>{
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	[mutationTypes.SET_LOGIN](state, value) {
		state.user.login = value;
	},
	[mutationTypes.SET_PASSWORD](state, value) {
		state.user.password = value;
	},
	[mutationTypes.SET_CONFIRMED_PASSWORD](state, value) {
		state.user.confirmedPassword = value;
	}
};

export {
	namespace, state, getters, actions, mutations
};

const signUpModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default signUpModule;
