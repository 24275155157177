import forgotPasswordTypes from "@/store/modules/forgotPassword/types";
import resetPasswordTypes from "@/store/modules/resetPassword/types";
import selectProfileTypes from "@/store/modules/selectProfile/types";
import signInTypes from "@/store/modules/signIn/types";
import signUpModule from "@/store/modules/signUp";
import agreePersonalDataTypes from "@/store/modules/agreePersonalData/types";

class StoreManager {
	signIn = {
		namespace: signInTypes.namespace
	};
	signUp = {
		namespace: signUpModule.namespace
	};
	forgotPassword = {
		namespace: forgotPasswordTypes.namespace
	};
	resetPassword = {
		namespace: resetPasswordTypes.namespace
	};
	selectProfile = {
		namespace: selectProfileTypes.namespace
	};
	agreePersonalData = {
		namespace: agreePersonalDataTypes.namespace
	};
}

const storeManager = new StoreManager();

export default storeManager;
