<template>
	<frp-sign-in-form-base title="Восстановить доступ">
		<v-responsive v-if="mode === ForgotPasswordModeType.INITIAL">
			<v-form :readonly="isFormSaving || isFormReadonly" :disabled="isFormDisabled"
					@submit.prevent="submit"
					:ref="refs.form" v-model="formValid">

				<div class="text-body-1 primary--text text--darken-1 text-center mx-auto mt-1">
					Укажите email, на который необходимо выслать инструкции по восстановлению доступа
				</div>

				<v-row>
					<v-col>
						<frp-text-field autocomplete="email"
										name="email"
										:placeholder="$t('placeholders.email')"
										label="Ваш email"
										class="mt-8"
										:rules="validation.email"
										v-model="email">
						</frp-text-field>
					</v-col>
				</v-row>
				<v-row class="flex-column">
					<v-col class="pt-0">
						<v-btn class="frp-btn"
							   type="submit"
							   color="primary"
							   :loading="isFormSaving"
							   :disabled="!formValid"
							   block>
							Сбросить пароль
						</v-btn>
					</v-col>
				</v-row>
			</v-form>
		</v-responsive>

		<template #actions v-if="mode === ForgotPasswordModeType.INITIAL">
			<v-col class="d-flex justify-center mt-7 px-10">
				<v-btn class="frp-btn" block outlined color="primary" :to="routeNames.signIn">
					<frp-icon :color="colors.primary.base" class="mr-2">
						<ico-arrow-left></ico-arrow-left>
					</frp-icon>
					К авторизации
				</v-btn>
			</v-col>
		</template>

		<div v-else class="d-flex flex-column">
			<frp-icon :color="colors.grey.base" class="justify-center mt-4 mb-8">
				<ico-email-open></ico-email-open>
			</frp-icon>

			<div class="text-body-1 primary--text text--darken-1 text-center mx-auto">
				Если аккаунт
				<span class="secondary--text">{{ email }}</span>
				активен, на почту будет отправлено письмо с инструкцией для сброса пароля
			</div>

			<v-btn class="frp-btn mt-10 mb-7" block color="primary" :to="routeNames.signIn">
				<frp-icon :color="colors.white.base" class="mr-2">
					<ico-arrow-left></ico-arrow-left>
				</frp-icon>
				К авторизации
			</v-btn>
			<v-btn class="frp-btn" block text color="primary" :disabled="!!timer" @click="save">
				{{ $vuetify.breakpoint.smOnly ? "Выслать еще раз" : "Выслать инструкции еще раз" }}
			</v-btn>

			<div class="text-caption grey--text mx-auto mt-4" :style="!!timer ? '' : 'visibility: hidden'">
				Выслать инструкции еще раз через:
				<span class="secondary--text ml-1">{{ timer }}с</span>
			</div>
		</div>
	</frp-sign-in-form-base>
</template>

<script>
import FrpTextField from "Components/fields/FrpTextField";
import FrpIcon from "Components/icon/FrpIcon";
import IcoEmailOpen from "Assets/img/icons/ico_email-open.svg";
import IcoArrowLeft from "Assets/img/icons/ico_arrow-left.svg";
import formMixin from "@/mixins/formMixin";
import routeNames from "Router/routeNames";
import storeManager from "@/store/manager";
import colorsMixin from "@/mixins/colorsMixin";
import { MutationTypes, ActionTypes } from "Store/modules/forgotPassword/types";
import { ForgotPasswordModeType } from "Store/modules/forgotPassword/types/forgotPasswordModeType";
import { prepareEmailRule, requiredRule } from "Utils/validation";
import FrpSignInFormBase from "Views/shared/FrpSignInFormBase";
import { createNamespacedHelpers } from "vuex";

const namespace = storeManager.forgotPassword.namespace;
const { mapState, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [formMixin, colorsMixin],
	data() {
		return {
			ForgotPasswordModeType: ForgotPasswordModeType,
			baseUrl: process.env.VUE_APP_BASE_URL,
			routeNames,
			namespace,
			isPasswordHidden: true,
			isConfirmedPasswordHidden: true,
			validation: {
				email: [requiredRule(), prepareEmailRule()]
			},
			timer: 0
		};
	},
	computed: {
		...mapState({
			state: state => state
		}),
		email: {
			get() {
				return this.state.email;
			},
			set(value) {
				this.setEmail(value);
			}
		},
		mode: {
			get() {
				return this.state.mode;
			},
			set(value) {
				this.setMode(value);
			}
		}
	},
	methods: {
		...mapMutations({
			setEmail: MutationTypes.SET_EMAIL,
			setMode: MutationTypes.SET_MODE
		}),
		...mapActions({
			sendEmail: ActionTypes.save
		}),
		async save() {
			await this.sendEmail();

			if(this.mode === ForgotPasswordModeType.SUCCESS)
				this.startTimer();
		},
		startTimer() {
			if(this.timer > 0) return;
			this.timer = 60;
			const interval = setInterval(() => {
				this.timer--;
				if(this.timer <= 0) clearInterval(interval);
			}, 1000);
		}
	},
	components: {
		FrpSignInFormBase,
		FrpIcon,
		FrpTextField,
		IcoEmailOpen,
		IcoArrowLeft
	}
};
</script>
