<template>
	<div class="d-flex flex-column justify-center mb-6">
		<slot></slot>
	</div>
</template>

<script>
export default {}
</script>

<style scoped>

</style>
