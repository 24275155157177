import NotImplementedException from "Exceptions/notImplementedException";
import { formTypes } from "Store/shared/form/types";
import snapshotMixinTypes from "Store/shared/snapshot/types";
import { savingType } from "Types/savingType";
import { mapInstanceState } from "Utils/vuexMapInstanse";
import { resolveAction, resolveGetter, resolveMutation } from "Utils/vuexModules";

const formMixin = {
	data() {
		return {
			refs: {
				form: "form"
			},
			formValid: true
		};
	},
	computed: {
		isCreateMode() {
			return !this.$route.params.id;
		},
		stateContainsUnsavedChanges() {
			return this.$store.getters[resolveGetter(this.namespace, snapshotMixinTypes.getterTypes.stateContainsUnsavedChanges)];
		},
		...mapInstanceState({
			isFormSaving: state => state.form.isSaving,
			isFormReadonly: state => state.form.isReadonly,
			isFormDisabled: state => state.form.isDisabled,
			isFormValid: state => state.form.isFormValid,
			isFormLoading: state => state.form.isLoadingState
		})
	},
	methods: {
		onFormCancel() {
			this.cancelChanges();
		},
		async cancelChanges() {
			await this.$store.dispatch(resolveAction(this.namespace, snapshotMixinTypes.actionTypes.cancelChanges));
		},
		validate() {
			return this.$refs[this.refs.form].validate();
		},
		setIsFormDisabled(value) {
			this.$store.commit(resolveMutation(this.namespace, formTypes.mutationTypes.SET_IS_FORM_DISABLED), value);
		},
		setIsFormValid(value) {
			this.$store.commit(resolveMutation(this.namespace, formTypes.mutationTypes.SET_IS_FORM_VALID), value);
		},
		async submit() {
			if(this.validate()) {
				try {
					this.isCreateMode
						? await this.save({ type: savingType.CREATE })
						: await this.save({ type: savingType.UPDATE, id: this.$route.params.id });
				} catch (e) {
					console.error(e);
					// Обработка производится на уровне store
				}
			}
		}
	},
	watch: {
		stateContainsUnsavedChanges(value) {
			if(!value) {
				this.$nextTick(() => {
					if(this.$refs[this.refs.form])
						this.$refs[this.refs.form].resetValidation();
				});
			}
		},
		formValid: {
			handler(value) {
				this.setIsFormValid(value);
			},
			immediate: true
		}
	}
};

export default formMixin;
