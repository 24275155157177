import FormModel from "@/store/shared/form/models/formModel";
import User from "@/store/modules/signUp/types/user";
import { Type } from "class-transformer";

export default class SignUpState {
	@Type(() => FormModel)
	form: FormModel;
	@Type(() => User)
	user: User;

	constructor(form: FormModel, user: User) {
		this.form = form;
		this.user = user;
	}
}
