export default class FileMeta {
	id: string;
	name: string;
	mimeType: string;
	namespace: string;
	signature: string;
	isLoading: boolean;

	constructor(id: string = "",
		name: string = "",
		mimeType: string = "",
		namespace: string = "",
		signature: string = "",
		isLoading: boolean = false)
	{
		this.id = id;
		this.name = name;
		this.mimeType = mimeType;
		this.namespace = namespace;
		this.signature = signature;
		this.isLoading = isLoading;
	}
}
