import FormModel from "@/store/shared/form/models/formModel";
import { ResetPasswordModeType } from "@/store/modules/resetPassword/types/resetPasswordModeType";
import { Type } from "class-transformer";

export default class ResetPasswordState {
	@Type(() => FormModel)
	form: FormModel;
	password: string;
	mode: ResetPasswordModeType;

	constructor(form: FormModel, mode: ResetPasswordModeType, password: string) {
		this.form = form;
		this.mode = mode;
		this.password = password;
	}
}
