import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/modules/agreePersonalData/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import { agreePersonalData, authorize, signInWithProfile } from "@/api/auth";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import { resolveNestedState } from "@/utils/vuexModules";
import storeManager from "@/store/manager";
import alertService from "@/store/modules/alerts/services/alertService";
import AgreePersonalDataState from "@/store/modules/agreePersonalData/types/agreePersonalDataState";
import router from "@/router";
import routeNames from "@/router/routeNames";
import SignInState from "@/store/modules/signIn/types/signInState";

const baseMixin = (new BaseMixinBuilder()).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new AgreePersonalDataState();
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<AgreePersonalDataState, any>>{};

const actions = <ActionTree<AgreePersonalDataState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	async [actionTypes.initialize]({ commit, rootState }) {
		const { redirectUri } = (resolveNestedState(rootState, storeManager.signIn.namespace) as SignInState);

		if(!redirectUri)
			await router.push({ name: routeNames.signIn });
	},
	async [actionTypes.submit]({ commit, state, rootState }, { id, payload }) {
		commit(mutationTypes.SET_IS_SAVING, true);

		try {
			await agreePersonalData(true);

			const { profiles, redirectUri } = (resolveNestedState(rootState, storeManager.signIn.namespace) as SignInState);

			let callbackUrl = await authorize(redirectUri);

			switch (profiles.length) {
				case 0:
				{
					window.location = callbackUrl;
					break;
				}
				case 1:
				{
					let [profile] = profiles;
					if(!profile.isSignatureRequired) {
						let redirectUri = await signInWithProfile({
							profileId: profile.id
						});

						window.location = await authorize(redirectUri);
					} else {
						await router.push({ name: routeNames.selectProfile, query: router.currentRoute.query });
					}

					break;
				}
				default:
				{
					await router.push({ name: routeNames.selectProfile, query: router.currentRoute.query });
				}
			}
		} catch (error: any) {
			console.error(error);

			if(error.detail) {
				alertService.addCustomError(error.detail);
			} else {
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_SAVING, false);
		}
	}
};

const mutations = <MutationTree<AgreePersonalDataState>>{
	...stateManipulationMixin.mutations,
	[mutationTypes.SET_IS_SAVING](state, value) {
		state.isSaving = value;
	}
};

export {
	namespace, state, getters, actions, mutations
};

const agreePersonalDataModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default agreePersonalDataModule;
