<template>
	<v-app class="sign-in">
		<v-container class="fill-height pa-0" fluid>
			<v-row class="fill-height pa-0" no-gutters>
				<v-col cols="6" lg="7" class="fill-height" style="position:fixed" v-if="$vuetify.breakpoint.smAndUp">
					<v-img :src="authImage" class="fill-height"></v-img>
				</v-col>
				<v-col cols="12"
					   sm="6" offset-sm="6"
					   offset-lg="7" lg="5"
					   class="pa-0">
					<v-container fluid class="fill-height" style="background-color: var(--v-white-darken2)">
						<v-row align="center" justify="center">
							<router-view :key="$route.meta.key || $route.name"/>
							<frp-alerts/>
						</v-row>
					</v-container>
				</v-col>
			</v-row>
		</v-container>
	</v-app>
</template>

<script>
import authImage from "Assets/img/images/auth-image.jpg";
import FrpAlerts from "Components/alerts/FrpAlerts";

export default {
	components: { FrpAlerts },
	data() {
		return {
			authImage
		}
	}
};
</script>
